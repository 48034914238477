<div class="flex flex-col items-center justify-center absolute inset-0 z-10">
  <svg class="w-16 h-16 md:w-32 md:h-32 lg:w-48 lg:h-48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 311 258">
    <defs>
      <style>
        .cls-1 {
          fill: #a96a52;
        }
      </style>
    </defs>
    <path
      class="cls-1"
      d="M111.18 106.44c12.72-12.44 18.43-17.81 31.48-4.14l38.89 38.89 14.95-14.93-39.84-39.85c-12.25-12.25-22.59-20.68-38-16.39-8.9 2.54-14.47 7.8-25.43 18.45l-50 50c-9.55 10.18-19.1 20.69-15 36.27 2.23 8.59 7.64 14.31 13.36 20.36l43.1 43.08 63.93-63.93-35.34-35.35-15.58 15.6 18 18-33.39 33.4-21.63-21.62c-13-13-11.13-16.22 3.19-30.54Z"
    />
    <path
      class="cls-1"
      d="m277.4 60.91-43.09-43.09-63.94 63.93 35.35 35.35 15.58-15.6-18-18 33.39-33.4 21.62 21.63c13 13 11.14 16.21-3.19 30.53l-47.3 47.3c-12.71 12.41-18.43 17.81-31.48 4.14l-38.89-38.89-14.95 14.94 39.84 39.84c12.25 12.25 22.59 20.68 38 16.39 8.9-2.54 14.47-7.8 25.44-18.45l50-50c9.56-10.18 19.1-20.68 15-36.27-2.23-8.59-7.63-14.31-13.36-20.35"
    />
  </svg>
  <h1 class="font-serif text-orange text-4xl sm:text-7xl lg:text-9xl xl:text-10xl mt-4">
    We are working on a new look
  </h1>

  <p
    class="
          text-gray text-xs
          md:text-2xl
          lg:text-4xl
          font-sans
          mt-8
          lg:mt-20
          uppercase
        "
  >
    Stay tuned!
  </p>
</div>
